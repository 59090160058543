import React from 'react'

import { Navbar, Home, About, Gallery, Reviews, Footer } from './components'

const App = () => {
  return (
    <div style={{ backgroundColor: 'black' }}>
      <Navbar />
      <Home />
      <About />
      <Gallery />
      <Reviews />
      <Footer />
    </div>
  )
}

export default App

// Built by FlowConcepts