import React, { useState } from 'react'
import { Link } from 'react-scroll'
import { AiFillFacebook, AiOutlineInstagram } from 'react-icons/ai'
import { FaBars, FaTimes } from 'react-icons/fa'

const Navbar = () => {
  const [nav, setNav] = useState(false)
  const handleClick = () => setNav(!nav)

  return (
    <div className="absolute lg:fixed w-full h-[100px] z-[9999] flex justify-between items-center px-16 text-white mx-auto">

      {/* Logo */}
      <div className="hidden md:flex cursor-pointer">
        <h1 className="text-2xl baskerville">BoozeTravelers</h1>
      </div>

      {/* Menu */}
      <ul className="hidden md:flex text-gray-400 font-light">
        <li className="mx-4 border-b-[1px] border-transparent cursor-pointer hover:border-b-[1px] hover:border-white hover:text-white">
          <Link to="home" spy={true} smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="mx-4 border-b-[1px] border-transparent cursor-pointer hover:border-b-[1px] hover:border-white hover:text-white">
          <Link to="about" spy={true} smooth={true} duration={500}>
            About us
          </Link>
        </li>
        <li className="mx-4 border-b-[1px] border-transparent cursor-pointer hover:border-b-[1px] hover:border-white hover:text-white">
          <Link to="gallery" spy={true} smooth={true} duration={500}>
            Gallery
          </Link>
        </li>
        <li className="mx-4 border-b-[1px] border-transparent cursor-pointer hover:border-b-[1px] hover:border-white hover:text-white">
          <Link to="reviews" spy={true} smooth={true} duration={500}>
            Reviews
          </Link>
        </li>
      </ul>

      {/* Social icons */}
      <div className="hidden md:flex gap-4">
        <a href="https://www.facebook.com/Booze-Travelers-458774657822665/" target="_blank" rel="noreferrer noopener">
        <AiFillFacebook className="h-5 w-5 hover:text-orange-new duration-300" />
        </a>
        <a href="https://www.instagram.com/boozetravelers_/" target="_blank" rel="noreferrer noopener">
        <AiOutlineInstagram className="h-5 w-5 hover:text-orange-new duration-300" />
        </a>
      </div>

      {/* Hamburger */}
      <div className="md:hidden z-10 cursor-pointer" onClick={handleClick}>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile menu */}
      <ul className={!nav ? "hidden" : "absolute top-0 left-0 w-full h-screen bg-black/80 flex flex-col justify-center items-center"}>
        <li className="my-6 text-4xl border-b-4 border-transparent hover:border-b-4 hover:border-orange-new">
          <Link onClick={handleClick} to="home" spy={true} smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="my-6 text-4xl border-b-4 border-transparent hover:border-b-4 hover:border-orange-new">
          <Link onClick={handleClick} to="about" spy={true} smooth={true} duration={500}>
            About us
          </Link>
        </li>
        <li className="my-6 text-4xl border-b-4 border-transparent hover:border-b-4 hover:border-orange-new">
          <Link onClick={handleClick} to="gallery" spy={true} smooth={true} duration={500}>
            Gallery
          </Link>
        </li>
        <li className="my-6 text-4xl border-b-4 border-transparent hover:border-b-4 hover:border-orange-new">
          <Link onClick={handleClick} to="reviews" spy={true} smooth={true} duration={500}>
            Reviews
          </Link>
        </li>
      </ul>

    </div>
  )
}

export default Navbar