import React from 'react'
import { BsTelephoneFill } from 'react-icons/bs'
import { IoIosMail } from 'react-icons/io'

const About = () => {
  return (
    <div name="about" className="w-full h-[900px] bg-black text-white lg:pb-8 md:pb-4 sm:pb-1 lg:mt-[-80px] md:mt-[-40px] sm:mt-[-20px] xs:mt-[-10px]">
      <div className="max-w-[1193px] mx-auto lg:px-8 md:px-4 px-2 flex flex-col justify-center items-center h-full">
        <h1 className="baskerville lg:text-5xl md:text-4xl text-3xl lg:mb-16 md:mb-8 sm:mb-4 mb-3">About <span className="text-orange-new">us</span></h1>
        <div className="text-[#9a9a9a] lg:text-sm text-xs leading-6 text-center lg:mb-16 md:mb-8 mb-4">
          <p className="mb-2">
            BoozeTravelers LLC was established in 2017 and is a registered business in the city of Rock Hill, SC. Bartending our way all over the area; we are experienced with all kinds of events including backyard parties, corporate gatherings, and weddings. We are a passionate, fun group and love what we do!
          </p>
          <p className="mb-2">
            Booze Travelers is licensed and insured, with 1 million in liquor liability and 1 million in general liability insurance. Your bartender arrives an hour before start time with a pop-up bar, 3 coolers, and, all bar tools. We tend bar for one flat rate. No price per guest counts, and no annoying watching the clock hourly rates.
          </p>
          <p className="mb-2">
            BTs bartenders are quick, clean, and anticipatory. We want you to enjoy your party.
          </p>
          <p className="mb-2">
            Understandably, planning an event can be overwhelming! We assist with amounts to purchase and in creating a bar menu or specialty drinks. We contact your venue and handle all insurance and licensing directly, with no hassle to you.
          </p>
          <p className="mb-2">
            You can find Booze Travelers on Facebook, Instagram, and on Thumbtack, a great app to help you find vendors in your area. Our reviews are “phenomenal”, and have a TOP PRO rating.
          </p>
          <p className="mb-2">
            Booze Travelers is flexible and booking is super easy, no deposit required or cancellation fees. We offer a military OR pay with cash discount <span className="text-xs">*if you qualify</span>. Booze Travelers holds dates for 7 days, allowing you some time to make a great choice for you.
          </p>
          <p>
            It is very easy to get in touch with us. We have extended hours Monday - Thursday from 8 am to 10 pm. We looking forward to hearing from you and answering any questions you might have. We look forward to serving you!
          </p>
        </div>
        
        <p className="baskerville text-gray-200 font-bold text-2xl lg:mb-4 md:mb-2 mb-1">
          CONTACT <span className="text-orange-new">US</span>
        </p>
        <div className="lg:flex md:flex block lg:text-lg text-md mt-1">
          <div className="flex justify-center">
            <BsTelephoneFill className="w-4 h-4 mt-1 text-orange-new" />
            <p className="text-gray-200 ml-2">803 810 4520</p>
          </div>
          <div className="flex justify-center">
            <IoIosMail className="w-6 h-6 lg:ml-16 ml-8 text-orange-new" />
            <p className="text-gray-200 ml-2">BoozeTravelers@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About