import React from 'react'

import { Stars } from '../images'

const Reviews = () => {
  return (
    <div name="reviews" className="w-full h-[1000px] bg-body">
      
      {/* Title & subtitle */}
      <div className="block text-center lg:mt-40 md:mt-20 mt-10">
        <p className="amiri text-[#8a8a8a] italic lg:text-xl md:text-lg text-md mb-1">Here's what people have to say</p>
        <h1 className="baskerville text-white lg:text-5xl md:text-4xl text-3xl">Customer <span className="text-orange-new">Reviews</span></h1>
      </div>

      {/* Reviews Grid Container */}
      <div className="w-full h-[600px] justify-center grid grid-cols-3 flex-wrap lg:mt-20 md:mt-10 mt-5 gap-4 pl-64 pr-64 review-boxes">

        {/* Reviews Containers */}
        {/* Review 1 */}
        <div className="lg:md:w-[420px] lg:md:h-[240px] w-[360px] h-[200px] border-[1px] border-[#8a8a8a] m-auto">
          <div className="flex justify-center lg:mt-8 md:mt-6 mt-4 text-white pl-8 pr-8 lg:gap-[46%] md:gap-[48%] gap-[51%]">
            <h1 className="baskerville lg:text-2xl md:text-xl text-lg">Otilla S.</h1>
            <img src={Stars} alt="stars" className="lg:h-4 h-3 translate-y-[50%] pointer-events-none" />
          </div>
          <div className="block pl-8 pr-8 lg:text-md text-sm lg:mt-6 md:mt-4 mt-3">
            <p className="text-[#8a8a8a]">
              I highly recommend Booze Travelers! They were incredibly responsive, answered my never ending questions, and were the ultimate professionals at my wedding. A great value and overall experience!
            </p>
          </div>
        </div>

        {/* Review 2 */}
        <div className="lg:md:w-[420px] lg:md:h-[240px] w-[360px] h-[200px] border-[1px] border-[#8a8a8a] m-auto">
          <div className="flex justify-center lg:mt-8 md:mt-6 mt-4 text-white pl-8 pr-8 lg:gap-[40%] md:gap-[43%] gap-[46%]">
            <h1 className="baskerville lg:text-2xl md:text-xl text-lg">Colleen F.</h1>
            <img src={Stars} alt="stars" className="lg:h-4 h-3 translate-y-[50%] pointer-events-none" />
          </div>
          <div className="block pl-8 pr-8 lg:text-sm text-xs lg:mt-6 md:mt-4 mt-3">
            <p className="text-[#8a8a8a]">
              Renee was amazing! She showed up early and had her own box of tools. She helped clean up, including taking trash out to the dumpster (which was totally not her responsibility). She was friendly and easy going. She went above and beyond! I couldn't be more pleased with her performance!
            </p>
          </div>
        </div>

        {/* Review 3 */}
        <div className="lg:md:w-[420px] lg:md:h-[240px] w-[360px] h-[200px] border-[1px] border-[#8a8a8a] m-auto">
          <div className="flex justify-center lg:mt-8 md:mt-6 mt-4 text-white pl-8 pr-8 lg:gap-[44%] md:gap-[47%] gap-[49%]">
            <h1 className="baskerville lg:text-2xl md:text-xl text-lg">Dutch V.</h1>
            <img src={Stars} alt="stars" className="lg:h-4 h-3 translate-y-[50%] pointer-events-none" />
          </div>
          <div className="block pl-8 pr-8 lg:text-md text-sm lg:mt-6 md:mt-4 mt-3">
            <p className="text-[#8a8a8a]">
              Extremely professional. High skilled and knowledge. Worked harder than wood pecker's lips. Booking her again.
            </p>
          </div>
        </div>

        {/* Review 4 */}
        <div className="lg:md:w-[420px] lg:md:h-[240px] w-[360px] h-[200px] border-[1px] border-[#8a8a8a] m-auto">
          <div className="flex justify-center lg:mt-8 md:mt-6 mt-4 text-white pl-8 pr-8 lg:gap-[41%] md:gap-[43%] gap-[46%]">
            <h1 className="baskerville lg:text-2xl md:text-xl text-lg">Tracey C.</h1>
            <img src={Stars} alt="stars" className="lg:h-4 h-3 translate-y-[50%] pointer-events-none" />
          </div>
          <div className="block pl-8 pr-8 lg:text-md text-sm lg:mt-6 md:mt-4 mt-3">
            <p className="text-[#8a8a8a]">
              The Booze Traveler staff were fantastic! They came early, were super helpful and did a fantastic job all night. I would absolutely use Booze Traveler in the future!
            </p>
          </div>
        </div>

        {/* Review 5 */}
        <div className="review-boxes-hide lg:md:w-[420px] lg:md:h-[240px] w-[360px] h-[200px] border-[1px] border-[#8a8a8a] m-auto">
          <div className="flex justify-center lg:mt-8 md:mt-6 mt-4 text-white pl-8 pr-8 lg:gap-[35%] md:gap-[38%] gap-[41%]">
            <h1 className="baskerville lg:text-2xl md:text-xl text-lg">Whitney C.</h1>
            <img src={Stars} alt="stars" className="lg:h-4 h-3 translate-y-[50%] pointer-events-none" />
          </div>
          <div className="block pl-8 pr-8 lg:text-md text-sm lg:mt-6 md:mt-4 mt-3">
            <p className="text-[#8a8a8a]">
              Do not hesitate to book Booze Travelers! They are attentive, hard working, and super friendly. They made our wedding day a blast for the guests!
            </p>
          </div>
        </div>

        {/* Review 6 */}
        <div className="review-boxes-hide lg:md:w-[420px] lg:md:h-[240px] w-[360px] h-[200px] border-[1px] border-[#8a8a8a] m-auto">
          <div className="flex justify-center lg:mt-8 md:mt-6 mt-4 text-white pl-8 pr-8 lg:gap-[48%] md:gap-[50%] gap-[52%]">
            <h1 className="baskerville lg:text-2xl md:text-xl text-lg">Amit P.</h1>
            <img src={Stars} alt="stars" className="lg:h-4 h-3 translate-y-[50%] pointer-events-none" />
          </div>
          <div className="block pl-8 pr-8 lg:text-md text-sm lg:mt-6 md:mt-4 mt-3">
            <p className="text-[#8a8a8a]">
              It was a great experience for us.
            </p>
          </div>
        </div>

      </div>

    </div>
  )
}

export default Reviews