import React from 'react'
import { AiFillFacebook, AiOutlineInstagram } from 'react-icons/ai'
import { BsTelephoneFill } from 'react-icons/bs'
import { IoIosMail } from 'react-icons/io'

const Footer = () => {
  return (
    <div className="w-full h-[400px] bg-[#090909] text-gray-200">
      
      {/* Title & Subtitle */}
      <div className="block text-center pt-10">
        <h1 className="baskerville text-white text-4xl">BoozeTravelers</h1>
        <p className="text-[#8a8a8a] text-sm pt-4">Bartenders for hire</p>
      </div>

      {/* Contact Information */}
      <div className="lg:flex md:flex block justify-center text-center mt-12 font-light lg:text-lg text-md">
        <div className="flex justify-center">
          <BsTelephoneFill className="w-4 h-4 mt-1 text-orange-new" />
          <p className="text-gray-200 ml-2">803 810 4520</p>
        </div>
        <div className="flex justify-center lg:mt-0 md:mt-0 mt-3">
          <IoIosMail className="w-6 h-6 lg:ml-16 md:ml-16 text-orange-new" />
          <p className="text-gray-200 ml-2">BoozeTravelers@gmail.com</p>
        </div>
        
      </div>

      {/* Line Break */}
      <div className="w-full flex justify-center items-center text-center mt-10">
        <div className="border-b-[1px] border-[#707070] lg:w-[50%] md:w-[60%] w-[80%] opacity-25" />
      </div>

      {/* Social Icons */}
      <div className="flex justify-center gap-6 mt-10">
        <a href="https://www.facebook.com/Booze-Travelers-458774657822665/" target="_blank" rel="noreferrer noopener">
          <AiFillFacebook className="w-6 h-6 p-[2px] border-[1px] border-orange-new text-orange-new" />
        </a>
        <a href="https://www.instagram.com/boozetravelers_/" target="_blank" rel="noreferrer noopener">
          <AiOutlineInstagram className="w-6 h-6 p-[2px] border-[1px] border-orange-new text-orange-new" />
        </a>
      </div>

      {/* All rights reserved */}
      <div className="block text-center mt-8">
        <p className="text-[#7A7A7A] text-sm font-light">BoozeTravelers LLC | All Rights Reserved</p>
      </div>

    </div>
  )
}

export default Footer