import React from 'react'
import { AiFillFacebook, AiOutlineInstagram } from 'react-icons/ai'

import { Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6 } from '../images'

const Gallery = () => {
  return (
    <div name="gallery" className="w-full h-[600px] bg-[#090909] text-gray-200 relative">

      {/* Grid Container */}
      <div className="h-full w-full grid grid-cols-2 absolute">

        {/* Left Grid Container */}
        <div className="flex flex-col lg:ml-64 md:ml-24 ml-4 justify-center relative">
          <div className="absolute top-[50%] translate-y-[-50%]">
            <p className="amiri text-[#9a9a9a] italic lg:text-xl md:text-lg text-md mb-1">Take a peek</p>
            <h1 className="baskerville text-white lg:text-5xl md:text-4xl text-3xl mb-10">The <span className="text-orange-new">Gallery</span></h1>
            <p className="text-[#8a8a8a] lg:text-sm text-xs leading-8 mb-10 mr-1">Take a look at some of the pictures we have taken during our events, and check out our social media accounts to see more frequent photos.</p>
            <h1 className="baskerville text-orange-new lg:text-xl md:text-lg sm:text-md mb-2">View more</h1>
            <div className="flex lg:gap-6 gap-4">
              <a href="https://www.facebook.com/Booze-Travelers-458774657822665/" target="_blank" rel="noreferrer noopener">
                <AiFillFacebook className="w-6 h-6 hover:text-orange-new duration-300" />
              </a>
              <a href="https://www.instagram.com/boozetravelers_/" target="_blank" rel="noreferrer noopener">
                <AiOutlineInstagram className="w-6 h-6 hover:text-orange-new duration-300" />
              </a>
            </div>
          </div>
          
        </div>

        {/* Right Grid Container */}
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 relative overflow-hidden">
          <div className="lg:h-[300px] gallery-images hover:scale-105 duration-300">
            <img src={Gallery1} alt="gallery1" className="w-full h-full" />
          </div>
          <div className="lg:h-[300px] gallery-images hover:scale-105 duration-300">
            <img src={Gallery2} alt="gallery2" className="w-full h-full" />
          </div>
          <div className="lg:h-[300px] gallery-images hover:scale-105 duration-300">
            <img src={Gallery3} alt="gallery3" className="w-full h-full" />
          </div>
          <div className="lg:h-[300px] gallery-images hover:scale-105 duration-300">
            <img src={Gallery4} alt="gallery4" className="w-full h-full" />
          </div>
          <div className="lg:h-[300px] gallery-images hover:scale-105 duration-300">
            <img src={Gallery5} alt="gallery5" className="w-full h-full" />
          </div>
          <div className="lg:h-[300px] gallery-images hover:scale-105 duration-300">
            <img src={Gallery6} alt="gallery6" className="w-full h-full" />
          </div>
        </div>

      </div>

    </div>
  )
}

export default Gallery