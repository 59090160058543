import React from 'react'
import { Link } from 'react-scroll'
import { VerticleButton as ScrollUpButton } from 'react-scroll-up-button'

const Hero = () => {
  return (
    <div name="home" className="w-full h-screen bg-black text-white">
      
      {/* Container */}
      <div className="max-w-[1193px] mx-auto flex flex-col justify-center items-center h-full hero gap-y-4">
        <ScrollUpButton 
          style={{ backgroundColor: '#f86800' }} 
          ToggledStyle={{ opacity: 0.75 }}
          AnimationDuration={1000}
        />
        <p className="amiri mb-[-2rem] text-2xl italic text-[#8a8a8a]">BoozeTravelers LLC</p>
        <div className="ellipse w-full">
          <h1 className="baskerville text-[72px] drop-shadow-lg text-center">Bartenders <span className="text-orange-new">For Hire.</span></h1>
        </div>
        <Link to="about" spy={true} smooth={true} duration={500}>
          <button
            type="button"
            className="w-[150px] h-12 border-[1px] border-white font-light text-sm mb-36 hover:bg-orange-new hover:border-orange-new duration-300"
          >
            Find out more
          </button>
        </Link>
      </div>

    </div>
  )
}

export default Hero